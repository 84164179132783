export const htmlTextLinkSingle = (
  htmlTextId: string,
  isEditMode: boolean = false
): string => {
  let link = `/html-text/${htmlTextId}`;
  if (isEditMode) {
    link += "?mode=edit";
  }
  return link;
};
