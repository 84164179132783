export const SEARCH_NAMES = {
  SORT_BY: "sortBy",
  SORT_ORDER: "sortOrder",
  SEARCH_TEXT: "searchText",
};

export const SEARCH_RANGES = {
  START: { value: "start", label: "od" },
  END: { value: "end", label: "do" },
  START_END: { value: "start_end", label: "od-do" },
  ALL: { value: "", label: "svi" },
};

export const SEARCH_SORT = {
  ASC: { value: 1, label: "rastuce" },
  DESC: { value: -1, label: "opadajuce" },
  SLUG: { value: "slug", label: "slug" },
  CONTENT: { value: "content", label: "content" },
};

export const SEARCH_NO_LIMIT = "SEARCH_NO_LIMIT";
