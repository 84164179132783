import { HtmlTextResult } from "common/html_text/dto/HtmlTextResult";
import styled from "styled-components";
import HtmlTextSingleNav from "components/common/html_text/HtmlTextSingleNav";
import { Divider } from "@mui/material";
import { htmlTextLinkSingle } from "common/html_text/html_text_link_common";
import NavLinkUndecorated from "components/common/style/link/NavLinkUndecorated";

type HtmlTextItemProps = {
  htmlText: HtmlTextResult;
};

const HtmlTextItem = ({ htmlText }: HtmlTextItemProps) => {
  return (
    <Div>
      <Divider />
      <Div>
        <NavLinkUndecorated to={htmlTextLinkSingle(htmlText.id)}>
          <div>{htmlText.slug}</div>
          <div>{htmlText.content} </div>
        </NavLinkUndecorated>
        <HtmlTextSingleNav htmlText={htmlText} />
      </Div>
    </Div>
  );
};

const Div = styled.div`
  margin-top: 2rem;
  line-height: 1.25rem;
`;

export default HtmlTextItem;
