import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

type NavItemSmallScreenProps = {
  path: string;
  title: string;
  clickHandle?: Function;
};

const NavItemSmallScreen = ({
  path,
  title,
  clickHandle,
}: NavItemSmallScreenProps) => {
  const navClickHandle = () => {
    if (clickHandle) {
      clickHandle();
    }
    // console.log("clickHandle");
  };

  return (
    <DivMenuItem>
      <NavLink to={path} onClick={navClickHandle}>
        {title}
      </NavLink>
    </DivMenuItem>
  );
};

const DivMenuItem = styled.div`
  font-size: 1.2rem;
  text-align: right;
  margin-top: 0.5rem;
`;

export default NavItemSmallScreen;
