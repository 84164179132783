import { useQuery } from "@tanstack/react-query";
import { authCurrentGet } from "common/auth/auth_api_common";
import { QUERY_KEYS } from "constant/react_query";

export const useAuthCurrent = () => {
  const { data: currentAuth } = useQuery(
    [QUERY_KEYS.AUTH_CURRENT_GET],
    authCurrentGet,
    { staleTime: Infinity }
  );
  return currentAuth;
};
