import { obDefaultValGet } from "common/common";
import { SEARCH_NAMES, SEARCH_RANGES } from "constant/search";

type SearchRangeOption = {
  value: string | number;
  label: string;
};

export const rangeOptionsStandard = (): SearchRangeOption[] => {
  const options: SearchRangeOption[] = [
    { value: SEARCH_RANGES.ALL.value, label: SEARCH_RANGES.ALL.label },
    {
      value: SEARCH_RANGES.START.value,
      label: SEARCH_RANGES.START.label,
    },
    { value: SEARCH_RANGES.END.value, label: SEARCH_RANGES.END.label },
    {
      value: SEARCH_RANGES.START_END.value,
      label: SEARCH_RANGES.START_END.label,
    },
  ];
  return options;
};

export const sortFieldsGet = (sortFieldOb: any = null) => {
  let result = null;
  if (sortFieldOb === null) {
    result = {
      [SEARCH_NAMES.SORT_BY]: "",
      [SEARCH_NAMES.SORT_ORDER]: "",
    };
  } else {
    result = {
      [SEARCH_NAMES.SORT_BY]: obDefaultValGet(
        sortFieldOb,
        SEARCH_NAMES.SORT_BY,
        ""
      ),
      [SEARCH_NAMES.SORT_ORDER]: obDefaultValGet(
        sortFieldOb,
        SEARCH_NAMES.SORT_ORDER,
        ""
      ),
    };
  }
  return result;
};
