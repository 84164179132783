import styled from "styled-components";
import { Control, FieldValues } from "react-hook-form";
import SelectField from "components/UI/SelectField";
import { SEARCH_SORT } from "constant/search";

type SearchSortProps = {
  control: Control<FieldValues, any>;
  sortByName: string;
  sortByDefaultValue: any;
  sortOrderName: string;
  sortOrderDefaultValue: any;
};

const SearchSort = ({
  control,
  sortByName,
  sortByDefaultValue,
  sortOrderName,
  sortOrderDefaultValue,
}: SearchSortProps) => {
  return (
    <DivSort>
      <label>Redosled</label>
      <SelectField
        label=""
        name={sortByName}
        control={control as unknown as Control<FieldValues, any>}
        options={[
          {
            value: "",
            label: "podrazumevano",
          },
          { value: SEARCH_SORT.SLUG.value, label: SEARCH_SORT.SLUG.label },
          {
            value: SEARCH_SORT.CONTENT.value,
            label: SEARCH_SORT.CONTENT.label,
          },
        ]}
        defaultValue={sortByDefaultValue}
        onSelect={(val: any) => {
          // console.log("on select val", val);
        }}
      />

      <SelectField
        label=""
        name={sortOrderName}
        control={control as unknown as Control<FieldValues, any>}
        options={[
          { value: "", label: "podrazumevano" },
          { value: SEARCH_SORT.ASC.value, label: SEARCH_SORT.ASC.label },
          { value: SEARCH_SORT.DESC.value, label: SEARCH_SORT.DESC.label },
        ]}
        defaultValue={sortOrderDefaultValue}
        onSelect={(val: any) => {}}
      />
    </DivSort>
  );
};

const DivSort = styled.div`
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  input {
    width: 5rem;
  }
`;

export default SearchSort;
