import React from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import styled from "styled-components";
import LogoutNav from "../LogoutNav/LogoutNav";
import NavigationGroup from "./NavigationGroup";
import MainNavigationSmallScreen from "./MainNavigationSmallScreen";
import {
  GENERAL_NAV_ELEMENTS,
  LIST_MAIN_NAV_ELEMENTS,
  NEW_ITEM_NAV_ELEMENTS,
} from "./MainNavigation.constant";

const LogoutNavDiv = styled.div`
  margin-left: 1rem;
  margin-top: 1rem;
`;

const drawer = (
  <div>
    <Toolbar />
    <Divider />
    <List>
      <ListItem key={"home"} disablePadding>
        <NavLink to="/">
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Start" />
          </ListItemButton>
        </NavLink>
      </ListItem>
      <Divider />
      {NEW_ITEM_NAV_ELEMENTS.map(({ title, path }) => (
        <ListItem key={title} disablePadding>
          <NavLink to={path}>
            <ListItemButton>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItemButton>
          </NavLink>
        </ListItem>
      ))}
    </List>
    <Divider />
    <NavigationGroup navElements={LIST_MAIN_NAV_ELEMENTS} dividerAdd={true} />
    <NavigationGroup navElements={GENERAL_NAV_ELEMENTS} dividerAdd={true} />
    <LogoutNavDiv>
      <LogoutNav />
    </LogoutNavDiv>
  </div>
);

const drawerWidth = 240;

const MainNavigation = () => {
  return (
    <>
      <MainNavigationSmallScreen />
      <BoxNav
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </BoxNav>
    </>
  );
};

const BoxNav = styled(Box).attrs({ className: "screen-large" })``;

export default MainNavigation;
