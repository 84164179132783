import React from "react";
import { NavLink } from "react-router-dom";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";

export type NavigationGroupProps = {
  navElements: Array<any>;
  dividerAdd?: boolean;
};

const NavigationGroup = ({ navElements, dividerAdd }: NavigationGroupProps) => (
  <>
    <List>
      {navElements.map(({ title, path }) => (
        <ListItem key={title} disablePadding>
          <NavLink to={path}>
            <ListItemButton>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItemButton>
          </NavLink>
        </ListItem>
      ))}
    </List>
    {dividerAdd && <Divider />}
  </>
);

export default NavigationGroup;
