import { HtmlTextResult } from "./dto/HtmlTextResult";

export const htmlTextFormDefaultValuesGet = (htmlText?: HtmlTextResult) => {
  if (htmlText) {
    return {
      slug: htmlText.slug,
      content: htmlText.content,
    };
  } else {
    return {
      slug: "",
      content: "",
    };
  }
};
